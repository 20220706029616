import { useState, useEffect } from 'react';
import { SCROLL_DIRECTION } from '../constants';

const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState(SCROLL_DIRECTION.NONE);
  const [prevOffset, setPrevOffset] = useState(0);

  // don't hide the menu until we scroll *at least*
  // minScroll pixels down the screen:
  const minimumScrollAmount = 50;

  const toggleScrollDirection = () => {
    const scrollY = Math.max(window.scrollY, minimumScrollAmount);
    const distance = prevOffset - scrollY;

    switch (true) {
      case distance > 0:
        setScrollDirection(SCROLL_DIRECTION.UP);
        break;
      case distance < 0:
        setScrollDirection(SCROLL_DIRECTION.DOWN);
        break;
      default:
        setScrollDirection(SCROLL_DIRECTION.NONE);
        break;
    }

    setPrevOffset(scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleScrollDirection);
    return () => {
      window.removeEventListener('scroll', toggleScrollDirection);
    };
  });
  return scrollDirection;
};

export default useScrollDirection;
