import React from 'react';
import useQueryBlogIndex from '../hooks/useQueryBlogIndex';
import { Link } from 'gatsby';

const BlogIndex = () => {
  // const [index, setIndex] = useState([]);

  const data = useQueryBlogIndex().allMarkdownRemark.edges;
  const parsed = parseIndex(data);

  const years = Object.keys(parsed);

  const list = years.map((year) => {
    return (
      <li key={Math.random()}>
        <details>
          <summary>Posts from {year}</summary>
          <ul>
            {parsed[year].map((post) => (
              <li key={post.slug}>
                <Link to={post.slug} key={`l-${post.slug}`}>
                  {post.title}
                </Link>
              </li>
            ))}
          </ul>
        </details>
      </li>
    );
  });

  return <>{list}</>;
};

const parseIndex = (data) => {
  return data.reduce((acc, { node }) => {
    const { year, title } = node.frontmatter;
    const { slug } = node.fields;
    const thisPost = { title, slug };
    acc[year] = acc[year] ? [...acc[year], thisPost] : [thisPost];
    return acc;
  }, {});
};

export default BlogIndex;
