// accessibility support
// Hidden from view by default, becomes visible when recveiving focus.
// @target an the id or other addressable reference to an landmark section on the same page.
import React from 'react';
import { skipToLandmarkStyles } from './skipToLandmark.module.scss';

const SkipToLandmark = ({ target, className, children, ...props }) => {
  const classes = className ? `SkipToLandmark ${skipToLandmarkStyles}` : skipToLandmarkStyles;
  return (
    <div className={classes} data-token-skip-content {...props}>
      <a href={target}>{children}</a>
    </div>
  );
};

export default SkipToLandmark;
