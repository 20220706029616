import React from 'react';

const Footer = (props) => {
  const d = new Date();
  const yyyy = d.getFullYear();
  return (
    <>
      <footer data-layout-footer data-use-palette {...props}>
        <p>
          Visit our main website <a href='http://theroyals.com.au'>TheRoyals.com.au</a>
        </p>
        <p>Copyright The Royals {yyyy}</p>
      </footer>
    </>
  );
};

export default Footer;
