import { useStaticQuery, graphql } from 'gatsby';

const useQueryBlogIndex = () => {
  return useStaticQuery(
    graphql`
      query blogIndex {
        allMarkdownRemark(
          filter: { frontmatter: { template: { eq: "blogPost" } } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                year: date(formatString: "YYYY")
              }
            }
          }
        }
      }
    `
  );
};

export default useQueryBlogIndex;
