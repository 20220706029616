import React, { createContext, useContext, useMemo } from 'react';
import { hamburgerButtonStyle } from './hamburgerButton.module.scss';

const ToggleContext = createContext();
const { Provider } = ToggleContext;

const HamburgerButton = ({ isOpen = false, onClick, children }) => {
  const value = useMemo(() => ({ isOpen: Boolean(isOpen) }), [isOpen]);

  return (
    <Provider value={value}>
      <button
        data-use-palette
        data-token-toggle
        aria-pressed={value.isOpen}
        className={hamburgerButtonStyle}
        onClick={onClick}
      >
        <span>{children}</span>
      </button>
    </Provider>
  );
};

const LabelOpen = ({ children }) => {
  const { isOpen } = useContext(ToggleContext);
  return isOpen ? children : '';
};

const LabelClosed = ({ children }) => {
  const { isOpen } = useContext(ToggleContext);
  return isOpen ? '' : children;
};

HamburgerButton.LabelClosed = LabelClosed;
HamburgerButton.LabelOpen = LabelOpen;

export default HamburgerButton;
