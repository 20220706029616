import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'gatsby';
import { SCROLL_DIRECTION } from '../constants';
import useScrollDirection from '../hooks/useScrollDirection';
import HamburgerButton from './hamburgerButton';
import BlogIndex from './blogIndex';

const Header = () => {
  const [isOpen, setIsOpen] = useState(0);
  const [className, setClassName] = useState('show');

  const newScrollDirection = useScrollDirection();
  const toggleNavState = () => setIsOpen((isOpen) => !isOpen);

  useEffect(() => {
    console.log('scroll');
    setClassName(newScrollDirection !== SCROLL_DIRECTION.DOWN ? 'show' : 'hide');
  }, [newScrollDirection]);

  return (
    <header
      data-layout-header
      data-nav-state={isOpen ? 'open' : 'closed'}
      data-use-palette
      className={className}
    >
      <h1>
        <Link to='/'>The Royals Blog Archives</Link>
      </h1>

      <HamburgerButton isOpen={isOpen} onClick={toggleNavState}>
        <HamburgerButton.LabelOpen>Hide Navigation</HamburgerButton.LabelOpen>
        <HamburgerButton.LabelClosed>Show Navigation</HamburgerButton.LabelClosed>
      </HamburgerButton>

      <nav className={isOpen ? 'open' : 'closed'}>
        <ul>
          <li>
            <a href='http://www.theroyals.com.au' title='Visit our main website'>
              The Royals Website
            </a>
          </li>
          {useMemo(
            () => (
              <BlogIndex />
            ),
            []
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
